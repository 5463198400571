import React from 'react'
import { ContentAdd } from '@fs/zion-icon'
import { useTranslation } from 'react-i18next'
import PillButton from './PillButton'
import { usePlaylistsContext } from '../../providers'
import { playlistsConfig } from '../../config'

export default function CreatePlaylistButton({ playlistsOverlay, disabled, ...overlay }) {
  const [t] = useTranslation()
  const { playlists } = usePlaylistsContext()
  const onClick = (e) => {
    playlistsOverlay?.close()
    overlay.handleClick(e)
  }

  return (
    <PillButton
      Icon={ContentAdd}
      color="blue"
      disabled={disabled || playlists.length === playlistsConfig.playlistLimit}
      emphasis="low"
      onClick={onClick}
      linkName="Create Playlist"
    >
      {t('playlists.collection.create.action', 'Create Playlist')}
    </PillButton>
  )
}
