import { isConferencePromo, isSessionDetail, traverseAndModify } from '../../../../lib'

/**
 * possible session types
 * - __typename: "ConferencePromo"; contentId
 * - __typename: "SessionDetail"; id
 */

// NOTE: if this gets too un-performant, look into sending only applicable objects such as items[] instead of all of the oneOffPageDetail

const isSessionObject = (obj) => {
  return (obj.contentId && isConferencePromo(obj)) || (obj.id && isSessionDetail(obj))
}

/**
 * Given the response from BSP, walks through the entire object like a tree and pulls out all of the video ids
 * @param {Object} data the response from BS: OneOffPageDetail, SessionDetail, etc.
 * @returns {Array} a list of video ids from the object
 * @deprecated use getSessionIds from src/hooks/helpers instead
 */
export const getVideoIds = (data) => {
  const ids = []
  traverseAndModify(data, isSessionObject, (obj) => {
    ids.push(obj.id || obj.contentId)
  })
  // dedup
  return [...new Set(ids)]
}

/**
 * Given the reponse from BS, walks through the entire object like a tree and adds the watch history to the video detail
 * @param {Object} data the response from BS: OneOffPageDetail, SessionDetail, etc.
 * @param {Array} watchHistoryEntries all the applicable watch history entries
 * @returns {Object} the original response with the watch history injected
 */
export const injectWatchHistory = (data, watchHistoryEntries) => {
  const injectedData = traverseAndModify(
    data,
    ({ id, contentId }) => watchHistoryEntries.some(({ sessionId }) => sessionId === id || sessionId === contentId),
    (obj) => {
      const historyIndex = watchHistoryEntries.findIndex(
        ({ sessionId }) => sessionId === obj.id || sessionId === obj.contentId
      )
      obj.watchHistory = watchHistoryEntries[historyIndex]
    }
  )
  return injectedData
}
