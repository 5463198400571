import React, { Children, useMemo } from 'react'
import {
  LayoutBand,
  Grid,
  List,
  ListItem,
  Divider,
  useContainerWidth,
  Separator,
  Cell,
  Skeleton,
  DataPreview,
  H2,
} from '@fs/zion-ui'
import { BorderScroller } from '@fs/zion-ui/quarks-authorized-use-only'
import ErrorBoundary from '@fs/zion-error-boundary'
import { useIsMdWidth, useElement } from '../../../hooks'
import { VideoProvider } from '..'

const LoadingCalendar = () => {
  return (
    <div>
      <Skeleton.H1 />
      {Children.toArray(
        [...Array(5)].map(() => (
          <>
            <Skeleton.Image height={65} width="100%" />
            <Separator />
          </>
        ))
      )}
    </div>
  )
}

/**
 * Plays a list of sessions.
 */
export default function ListPlayer({
  sessions,
  loading,
  autoplay,
  heading,
  noSessionText,
  onEnd,
  playingIndex = 0,
  listItems,
  ...props
}) {
  const isMdWidth = useIsMdWidth()
  const atWidth = useContainerWidth()
  // FIXME: this is fragile; is there a better way to layout
  const [videoContainerRef, { clientHeight: videoHeight = 0 }] = useElement()
  const [headerContainerRef, { clientHeight: headerHeight = 0 }] = useElement()

  const session = sessions[playingIndex]

  // this is an optimization and a feature; it makes it so that if two videos with the same ids are back to back, the player will not reload
  const video = session?.item?.video?.videoProvider
  const videoProvider = useMemo(() => video ?? {}, [video])

  return (
    <ErrorBoundary>
      <LayoutBand as="section">
        <Grid columns={isMdWidth ? 1 : 2}>
          <div ref={videoContainerRef} columns={atWidth({ lg: 9 })}>
            <ErrorBoundary>
              <VideoProvider
                autoplay={autoplay}
                videoProvider={videoProvider}
                onEnd={onEnd}
                loading={loading}
                sessionId={session?.item?.contentId}
                videoStartTime={session?.date}
                {...props}
              />
            </ErrorBoundary>
          </div>
          <Cell columns={atWidth({ lg: 3 })}>
            <div ref={headerContainerRef}>
              <H2 size="H5">{heading}</H2>
            </div>
            <DataPreview.Loading loading={loading}>
              <LoadingCalendar />
            </DataPreview.Loading>
            <DataPreview.Ready loading={loading}>
              <BorderScroller>
                <div style={{ height: videoHeight - headerHeight, paddingInlineEnd: 12 }}>
                  <List>
                    {listItems}
                    {sessions.length === 0 && (
                      <>
                        <Divider />
                        <ListItem primaryText={noSessionText} />
                      </>
                    )}
                  </List>
                </div>
              </BorderScroller>
            </DataPreview.Ready>
          </Cell>
        </Grid>
      </LayoutBand>
    </ErrorBoundary>
  )
}
