import { useCallback, useEffect, useState } from 'react'
import { useUser } from '@fs/zion-user'
import { getChunkedPlaylistsByItems, injectPlaylists } from './helpers'
import { getSessionIds } from '../../helpers'
import useStableObject from '../../useStableObject'

const combinePlaylistsWithData = async (data) => {
  const ids = getSessionIds(data)
  if (ids.length === 0) {
    return data
  }
  const playlistItems = await getChunkedPlaylistsByItems(ids)
  const injectedData = injectPlaylists(data, playlistItems)
  return injectedData
}

export default function usePlaylistItems({ loading, data }) {
  const [state, setState] = useState(data)
  const { signedIn } = useUser()

  const updateStateWithPlaylists = useCallback(async () => {
    setState(await combinePlaylistsWithData(data))
  }, [data])

  const stableData = useStableObject(data)
  useEffect(() => {
    if (!loading && signedIn) {
      updateStateWithPlaylists()
    } else {
      setState(stableData)
    }
  }, [stableData, loading, signedIn, updateStateWithPlaylists])

  return { data: state, loading }
}
