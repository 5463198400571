import React, { useEffect } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { i18n } from '@fs/zion-locale'
import { boolAttr } from '@fs/zion-frontend-friends'
import { useExperiment } from '@fs/zion-experiments'
import { useFeatureFlag } from '@fs/zion-flags'
import { UsabillaWrapper } from '@fs/zion-usabilla'
import { colors } from '@fs/zion-ui'
import NavLink from './NavLink'
import LanguageLink from './LanguageLink'
import { useIsMdWidth } from '../../hooks'
import { storeConfig, usabillaConfig } from '../../config'
import { getProspectiveLanguages } from '../../lib'

const hasDocument = typeof document !== 'undefined'

const navCss = css`
  margin-bottom: 24px;
  text-align: center;
  &[is-md-width] {
    /* On mobile, they need a bit more space */
    line-height: 32px;
  }
`

const whiteLinkCss = css`
  &,
  &:visited,
  &:active,
  &:focus {
    color: ${colors.gray100} !important;
    text-decoration: none;
  }
  &:hover {
    color: ${colors.gray100};
    text-decoration: underline;
  }
`

const langPickerLinkCss = css`
  &::before {
    content: ' ';
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAWlBMVEUAAAB3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3cyECA3AAAAHXRSTlMApeAO+po1/H/uyXcZCvPortXBi04luZ9xVz4EE05v0OMAAACjSURBVBjTZU9JEoMwDJNDQgJZCDuU+v/frJ1OT9WMD14kWVD0zlhrXI8vOuKBjGVm6lo/8nDeNU119aNOSFYlLu8daWYSPiumsAO5Fu7hipd+b+QzWgdDcUkXFE5ODWx4wpqavBpZ+KBGJT7AVQ1boQCYmOcXgFsobsjAJsIJaumkhIOZVwiC2IL8BpglQw+pve5DPkQhB3n9F+5wkYYW7i/+B2iSCWl6HoSJAAAAAElFTkSuQmCC')
      top left no-repeat;
    padding-inline-start: 24px;
    padding-bottom: 1px;
  }
  &,
  &:visited,
  &:active,
  &:focus {
    color: ${colors.gray100} !important;
    text-decoration: none;
  }
  &:hover {
    color: ${colors.gray100};
    text-decoration: underline;
  }
`

const trustArcCss = css`
  z-index: 2;
`

const loadScript = (src) => {
  if (hasDocument) {
    const tag = document.createElement('script')
    tag.async = true
    tag.src = src
    document.getElementsByTagName('head')[0].appendChild(tag)
  }
}

export default function NavLinks({ onLangClick = () => {}, ...props }) {
  const [trustArcV2Ex] = useExperiment('trustArcV2Ex')
  const storeFlag = useFeatureFlag('rootstech_store')
  const isMdWidth = useIsMdWidth()

  useEffect(() => {
    // TrustArc cookie prefs banner and overlay
    const trustArcDomainArg = trustArcV2Ex ? 'familysearchv2.org' : 'familysearch.org'
    let trusteLang = i18n.language
    if (trusteLang === 'zh') {
      trusteLang = 'zh-tw'
    } else if (trusteLang === 'zh-hans') {
      trusteLang = 'zh-cn'
    }
    const scriptUrl = `//consent.trustarc.com/notice?domain=${trustArcDomainArg}&c=teconsent&js=nj&noticeType=bb&text=true&gtm=1&pcookie&pn=1-0&cdn=1&language=${trusteLang}`
    loadScript(scriptUrl)
  }, [trustArcV2Ex])

  const { t } = useTranslation()

  // these extra links will lead to pages that are only in english. So don't show them unless the page is in english
  const isEnglishSpeaker = document.documentElement.lang === 'en'
  const usabillaInstance = new UsabillaWrapper(usabillaConfig.url)
  const onFeedbackClick = () => {
    usabillaInstance.openForm()
  }

  return (
    <>
      {/* trustArc banner needs this injection div */}
      {<div id="consent_blackbar" css={trustArcCss} />}
      {/* Without an aria-label, this is just listed as "navigation" to screen-readers (now lists as "Footer navigation") */}
      <nav aria-label={t('nav.footer', 'Footer')} css={navCss} is-md-width={boolAttr(isMdWidth)} {...props}>
        <div>
          <NavLink
            text={t('common-ui:feedback.label', 'Feedback')}
            linkName="Footer: Feedback"
            onClick={onFeedbackClick}
            css={whiteLinkCss}
          />
          <NavLink
            text={t('nav.cookie-preferences', 'Cookie Preferences')}
            linkName="Footer: Cookie Preferences"
            css={whiteLinkCss}
            id="teconsent"
          />

          {!getProspectiveLanguages().includes(i18n.language) && (
            <NavLink text={t('navigation.faq.label', 'FAQ')} linkName="Footer: FAQ" css={whiteLinkCss} to="/faq" />
          )}

          {isEnglishSpeaker && (
            <>
              {storeFlag.isOn && (
                <NavLink
                  text={t('navigation.rootstech-store.label', 'RootsTech Store')}
                  linkName="Footer: RootsTech Store"
                  css={whiteLinkCss}
                  href={storeConfig.to}
                  external
                  target="_blank"
                />
              )}
            </>
          )}
          <NavLink
            text={t('virtual-booth.contact-us', 'Contact Us')}
            linkName="Footer: Contact Us"
            css={whiteLinkCss}
            href="mailto:info@rootstech.org"
          />

          <LanguageLink onClick={onLangClick} css={langPickerLinkCss} />
        </div>
      </nav>
    </>
  )
}
