import React, { useState } from 'react'
import {
  Ellipsis,
  Row,
  Paragraph,
  Card,
  useContainerWidth,
  Bleed,
  WhiteTheme,
  H3,
  Separator,
  Expander,
  ExpanderContent,
} from '@fs/zion-ui'
import { Link } from '@fs/zion-router'
import { css } from '@emotion/core'
import { ArrowCaret } from '@fs/zion-icon'
import { useRandomId } from '@fs/zion-frontend-friends'
import VideoThumbnail from './VideoThumbnail'
import {
  SafeRichText,
  CreatorExhibitorLinks,
  getCustomImage,
  getPercentageWatched,
  StrongLink,
  TabletButton,
} from '../..'

const containerCss = css`
  display: flex;
  align-items: center;
`

const ThumbnailContainer = ({ shouldWrap, to, children, shrinkThumbnail, ...props }) => {
  if (shouldWrap) {
    return (
      <Bleed css={containerCss} top sides>
        <Link to={to} style={{ width: '100%' }} {...props}>
          {children}
        </Link>
      </Bleed>
    )
  }
  const minimumWidth = shrinkThumbnail ? 178 : 286
  return (
    <div css={containerCss} style={{ width: minimumWidth }}>
      <Card to={to} {...props}>
        <Bleed all>{children}</Bleed>
      </Card>
    </div>
  )
}

const BadgesContainer = ({ badges, timestamp, classroom, shouldWrap }) => {
  return shouldWrap ? (
    <div>
      <Row guttersX="none" alignX="end">
        {timestamp}
        {classroom}
      </Row>
      {badges}
    </div>
  ) : (
    <Row>
      <div growX>{badges} </div>
      <Row guttersX="none">
        {timestamp}
        {classroom}
      </Row>
    </Row>
  )
}

const ExpanderButton = ({ isOpen, onClick, ...rest }) => {
  return (
    <TabletButton
      Icon={ArrowCaret}
      iconDirection={isOpen ? 'up' : 'down'}
      onClick={onClick}
      color="blue"
      emphasis="high"
      {...rest}
    />
  )
}

export default function CardListItem({
  actions,
  badges,
  classroom,
  creators,
  dataForVideo,
  description,
  exhibitors,
  heading,
  linkName,
  timestamp,
  to,
  expandable,
  additionalContent,
}) {
  const atWidth = useContainerWidth()
  const { video = {}, comingSoon, contentLocale, linkName: thumbnailLinkName, isSession, shrinkThumbnail } =
    dataForVideo || {}
  const { videoDuration } = video || {}

  const thumbnail = getCustomImage(dataForVideo)
  const percentageWatched = getPercentageWatched(dataForVideo)
  const hasCreatorsExhibitors = creators?.length > 0 || exhibitors?.length > 0
  const shouldWrap = atWidth({ default: true, md: false })

  const Container = ({ children, ...props }) =>
    expandable ? <Expander {...props}>{children}</Expander> : <div>{children}</div>
  const Contents = expandable ? ExpanderContent : 'div'

  const [open, setOpen] = useState(false)
  const controlsId = useRandomId()

  const expanderButton = <ExpanderButton isOpen={open} onClick={() => setOpen((v) => !v)} aria-controls={controlsId} />

  return (
    <Card>
      <Row wrap={shouldWrap} alignX={shouldWrap ? 'center' : undefined}>
        {dataForVideo && (
          <ThumbnailContainer
            shouldWrap={shouldWrap}
            to={to}
            noShrinkX={!shouldWrap}
            growX={shouldWrap}
            linkName={thumbnailLinkName}
            shrinkThumbnail={shrinkThumbnail}
          >
            <VideoThumbnail
              thumbnail={thumbnail}
              duration={videoDuration}
              heading={heading}
              comingSoon={comingSoon}
              contentLocale={contentLocale}
              percentageWatched={percentageWatched}
              isSession={isSession}
            />
          </ThumbnailContainer>
        )}
        <Container growX open={open} setOpen={setOpen} ariaControlsId={controlsId}>
          <BadgesContainer badges={badges} timestamp={timestamp} classroom={classroom} shouldWrap={shouldWrap} />
          {(badges || timestamp || classroom) && <Separator size="xxs" />}
          <H3 size="H5" data-testid="list-item-title">
            <WhiteTheme>
              <StrongLink to={to} noUnderline linkName={linkName}>
                {heading}
              </StrongLink>
            </WhiteTheme>
          </H3>
          <CreatorExhibitorLinks creators={creators} exhibitors={exhibitors} />
          <Contents>
            {additionalContent}
            <Paragraph size="sm">
              {!expandable ? (
                <Ellipsis as="span" lineClamp={hasCreatorsExhibitors ? 2 : 3} noTooltip>
                  <SafeRichText as="span" dirtyInput={description} />
                </Ellipsis>
              ) : (
                <SafeRichText as="span" dirtyInput={description} />
              )}
            </Paragraph>
            <Separator />
            {actions}
            {expandable && <Separator size="nano" />}
          </Contents>
          {shouldWrap && expandable && (
            <>
              {!open && <Separator size="xxs" />}
              <Row alignX="center">{expanderButton}</Row>
            </>
          )}
        </Container>
        {expandable && !shouldWrap && expanderButton}
      </Row>
    </Card>
  )
}
