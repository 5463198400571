import { useQuery } from '@apollo/client'
import { getOneOffPage } from '../api'
import useErrorHandlerOverlay from './useErrorHandlerOverlay'
import { brightspotConfig } from '../config'
import { useVideoWatchProgress } from './useWatchHistory'
import { usePlaylistItems } from './playlists'

const { imageSizes } = brightspotConfig
const {
  leadThumbnailImage,
  leadPromoImage,
  leadProfileImage,
  leadImage,
  profileImage,
  promoImage,
  thumbnailImage,
} = imageSizes

export default function useOneOffPageData({ path }) {
  const variables = {
    path,
    ...leadPromoImage,
    ...leadProfileImage,
    ...leadThumbnailImage,
    ...leadImage,
    ...profileImage,
    ...promoImage,
    ...thumbnailImage,
  }
  const { loading, data, error } = useQuery(getOneOffPage(), { variables })
  useErrorHandlerOverlay(error)

  // TODO: Refactor this to use a single hook that combines all the data
  const { data: dataWithWatchHistory, loading: watchHistoryLoading } = useVideoWatchProgress({
    loading,
    data: data?.OneOffPageDetail,
  })
  const { data: dataWithPlaylists, loading: playlistsLoading } = usePlaylistItems({
    loading: watchHistoryLoading,
    data: dataWithWatchHistory,
  })

  return { loading: playlistsLoading, data: dataWithPlaylists, error }
}
