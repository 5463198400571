import React from 'react'
import { Bleed, Card, LayoutBand, Row, Separator, H2 } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { useFeatureFlag } from '@fs/zion-flags'
import CountDown from './CountDown'
import countdownTimerRegistrationBackgroundJpg from '../../images/countdown-timer-registration-background.jpg'
import { conferenceConfig } from '../../config'
import { useIsMdWidth } from '../../hooks'
import ResponsiveLayoutBand from '../ResponsiveLayoutBand'

const CountDownBanner = ({
  showWithSiteExperienceFlag = 'countdown',
  countdownImageBackground = countdownTimerRegistrationBackgroundJpg,
}) => {
  const [t] = useTranslation()
  const siteExperienceFlag = useFeatureFlag('rootstech_site_experience')
  const isMdWidth = useIsMdWidth()

  if (siteExperienceFlag.treatment !== showWithSiteExperienceFlag) return null

  return (
    <ResponsiveLayoutBand>
      <Card backgroundImage={countdownImageBackground}>
        <Bleed sides>
          <LayoutBand>
            <Separator size="xs" />
            <Row alignX="center" alignY="middle" wrap>
              <H2 supplementary centered={isMdWidth}>
                {t('homepage.countdown.begins', 'Conference Begins:')}
              </H2>
              <CountDown startTimestamp={conferenceConfig.startTimestamp} noReload />
            </Row>
            <Separator />
          </LayoutBand>
        </Bleed>
      </Card>
    </ResponsiveLayoutBand>
  )
}

export default CountDownBanner
