import React from 'react'
import { useFeatureFlag } from '@fs/zion-flags'
import { useTranslation } from 'react-i18next'
import { i18n } from '@fs/zion-locale'
import { ListItem } from '@fs/zion-ui'
import { menuNavConfig, languageConfig, callForPresentationsConfig } from '../../config'
import { useAnonSessionPermission, useNavBarItems } from '../../hooks'

export const getIsComplexSite = () => {
  if (languageConfig.complexSiteLanguages.includes(i18n.language)) {
    return true
  }
  return false
}

export const filterNavItems = (items, simpleNavItems) => {
  if (getIsComplexSite()) {
    return items
  }

  const filteredItems = [...items].filter(({ 'data-testid': testId }) => simpleNavItems.includes(testId))

  return filteredItems
}

export function usePastEventMenuItems(dense, onClick, phase, removeFiller = false) {
  const navItems = useNavBarItems()
  const userLocale = i18n.language
  const scheduleFlag = useFeatureFlag('rootstech_schedule')
  const rarFlag = useFeatureFlag('rootstech_rar')
  const [t] = useTranslation()
  const buttonProps = {
    onClick,
    dense,
    startElement: !removeFiller ? <ListItem.Filler /> : undefined,
  }

  const pastEventItems = []
  if (scheduleFlag.isOn) {
    pastEventItems.push({
      'data-testid': 'eventMenu-schedule',
      to: '/schedule',
      primaryText: t('navigation.class-schedule.label', 'Class Schedule'),
      linkName: `Menu: ${phase}: Class Schedule`,
      ...buttonProps,
    })
  }
  pastEventItems.push({
    'data-testid': 'eventMenu-speakers',
    to: '/speakers',
    primaryText: t('navigation.speakers.label', 'Speakers'),
    linkName: `Menu: ${phase}: Speakers`,
    ...buttonProps,
  })
  if (!menuNavConfig.expoHallHiddenLocales.includes(userLocale)) {
    pastEventItems.push({
      'data-testid': 'eventMenu-expoHall',
      to: '/expohall',
      primaryText: t('navigation.expo-hall.label', 'Expo Hall'),
      linkName: `Menu: ${phase}: Expo Hall`,
      ...buttonProps,
    })
  }
  if (rarFlag.isOn) {
    pastEventItems.push({
      'data-testid': 'eventMenu-relatives',
      to: `/${i18n.language ?? 'en'}/connect`,
      primaryText: t('navigation.relatives-at-rootstech.label', 'Relatives at RootsTech'),
      linkName: `Menu: ${phase}: RAR`,
      external: true,
      target: '_blank',
      ...buttonProps,
    })
  }
  pastEventItems.push({
    'data-testid': 'eventMenu-archivists',
    to: '/events/archives-and-records-custodians-symposium-arc',
    primaryText: t('navigation.arc-symposium.label', 'ARC Symposium'),
    linkName: `Menu: ${phase}: ARC Symposium`,
    ...buttonProps,
  })
  if (userLocale === 'en') {
    pastEventItems.push({
      'data-testid': 'eventMenu-syllabi',
      to: '/events/syllabi',
      primaryText: t('menu.in-person-syllabi.label', 'In-Person Syllabi'),
      linkName: `Menu: ${phase}: In Person Syllabi`,
      ...buttonProps,
    })
  }

  return filterNavItems(pastEventItems, navItems)
}

export function useGetInvolvedMenuItems(dense, onClick, phase, removeFiller = false) {
  const navItems = useNavBarItems()
  const [t] = useTranslation()
  const userLocale = i18n.language
  const hasPersonalDataPermission = useAnonSessionPermission()
  const callForPresentationsFlag = useFeatureFlag('rootstech_callForPresentations')
  const marketingFlag = useFeatureFlag('rootstech_marketing')
  const sponsorFlag = useFeatureFlag('rootstech_sponsor')
  const buttonProps = {
    onClick,
    dense,
    startElement: !removeFiller ? <ListItem.Filler /> : undefined,
  }

  const getInvolvedItems = []
  if (marketingFlag.isOn) {
    getInvolvedItems.push({
      'data-testid': 'eventMenu-getInvolved-marketing',
      to: '/events/rootstech/media',
      primaryText: t('navigation.join-our-media-team.label', 'Join Our Media Team'),
      linkName: `Menu: ${phase}: Join Our Media Team`,
      ...buttonProps,
    })
  }
  if (
    callForPresentationsFlag.isOn &&
    hasPersonalDataPermission &&
    callForPresentationsConfig.supportedLanguages.includes(userLocale)
  ) {
    getInvolvedItems.push({
      'data-testid': 'eventMenu-getInvolved-callForContent',
      to: '/call-for-presentations/instructions',
      primaryText: t('navigation.speaking', 'Call for Presentations'),
      linkName: `Menu: ${phase}: Call for Presentations`,
      ...buttonProps,
    })
  }
  if (sponsorFlag.isOn && hasPersonalDataPermission && userLocale === 'en') {
    getInvolvedItems.push({
      'data-testid': 'eventMenu-getInvolved-sponsor',
      to: '/expohall/sponsor',
      primaryText: t('navigation.sponsor.label', 'Become a Sponsor or Exhibitor'),
      linkName: `Menu: ${phase}: Become a Sponsor or Exhibitor`,
      ...buttonProps,
    })
  }

  return filterNavItems(getInvolvedItems, navItems)
}

export function useHowToAttendMenuItems(dense, onClick, phase, removeFiller = false) {
  const navItems = useNavBarItems()
  const [t] = useTranslation()
  const inPersonFlag = useFeatureFlag('rootstech_in_person')
  const buttonProps = {
    onClick,
    dense,
    startElement: !removeFiller ? <ListItem.Filler /> : undefined,
  }

  const howToAttendItems = [
    {
      'data-testid': 'eventMenu-overview',
      to: getIsComplexSite() ? '/registration/' : '/about',
      primaryText: t('navigation.overview.label', 'Overview'),
      linkName: `Menu: ${phase}: Overview`,
      ...buttonProps,
    },
    {
      'data-testid': 'eventMenu-howAttend-virtually',
      to: '/registration/online/',
      primaryText: t('navigation.virtually-online.label', 'Online Event'),
      linkName: `Menu: ${phase}: Online Event`,
      onClick,
      ...buttonProps,
    },
  ]

  if (inPersonFlag.isOn) {
    const inPersonAtSaltLakeCity = {
      'data-testid': 'eventMenu-howAttend-inPerson',
      to: '/registration/in-person/',
      primaryText: t('navigation.in-person-salt-lake-city.label', 'In-Person at Salt Lake City'),
      linkName: `Menu: ${phase}: In-Person at Salt Lake City`,
      ...buttonProps,
    }
    howToAttendItems.splice(1, 0, inPersonAtSaltLakeCity)
  }

  return filterNavItems([...howToAttendItems], navItems)
}

export function useEventMenuItems(dense, onClick, phase, removeFiller = false) {
  const navItems = useNavBarItems()
  const siteExperienceFlag = useFeatureFlag('rootstech_site_experience')
  const rootstechRarTreatments = ['countdown', 'conference']
  const userLocale = i18n.language
  const scheduleFlag = useFeatureFlag('rootstech_schedule')
  const expoHallFlag = useFeatureFlag('rootstech_expohall')
  const rarFlag = useFeatureFlag('rootstech_rar')
  const [t] = useTranslation()
  const buttonProps = {
    onClick,
    dense,
    startElement: !removeFiller ? <ListItem.Filler /> : undefined,
  }

  const eventItems = []

  if (languageConfig.complexSiteLanguages.includes(userLocale)) {
    eventItems.push({
      'data-testid': 'eventMenu-schedule',
      to: '/schedule',
      primaryText: scheduleFlag.isOn
        ? t('navigation.class-schedule.label', 'Class Schedule')
        : t('home.more-to-explore.item.primary-text-class-preview', 'Class Preview'),
      linkName: scheduleFlag.isOn ? `Menu: ${phase}: Class Schedule` : `Menu: ${phase}: Class Preview`,
      ...buttonProps,
    })
  }
  eventItems.push({
    'data-testid': 'eventMenu-speakers',
    to: '/speakers',
    primaryText: t('navigation.speakers.label', 'Speakers'),
    linkName: `Menu: ${phase}: Speakers`,
    ...buttonProps,
  })
  if (expoHallFlag.isOn && !menuNavConfig.expoHallHiddenLocales.includes(userLocale)) {
    eventItems.push({
      'data-testid': 'eventMenu-expoHall',
      to: '/expohall/',
      primaryText: t('navigation.expo-hall.label', 'Expo Hall'),
      linkName: `Menu: ${phase}: Expo Hall`,
      ...buttonProps,
    })
  }
  if (rarFlag.isOn && rootstechRarTreatments.includes(siteExperienceFlag.treatment)) {
    eventItems.push({
      'data-testid': 'eventMenu-relatives',
      to: `/${i18n.language ?? 'en'}/connect`,
      primaryText: t('navigation.relatives-at-rootstech.label', 'Relatives at RootsTech'),
      linkName: `Menu: ${phase}: RAR`,
      external: true,
      target: '_blank',
      ...buttonProps,
    })
  }

  return filterNavItems(eventItems, navItems)
}
