import React from 'react'
import { useTranslation } from 'react-i18next'
import { trackLink } from '@fs/zion-analytics'
import { ContentAdd, ContentCheck } from '@fs/zion-icon'
import { useUser } from '@fs/zion-user'
import { useOverlay } from '@fs/zion-ui'
import { useFeatureFlag } from '@fs/zion-flags'
import { useLoggedOutOverlay } from '../../providers/LoggedOutOverlayProvider'
import ChewableButton from './ChewableButton'
import PlaylistManagementOverlay from '../../components/playlists/components/PlaylistManagementOverlay'
import PlaylistsOverlay from '../PlaylistsOverlay'
import CreatePlaylistOverlay from '../../components/playlists/components/CreatePlaylistOverlay'

export default function PlaylistsButton({ item, sessionId, asIconButton, size, ...props }) {
  const [t] = useTranslation()
  const improvedUxEnabled = useFeatureFlag('rootstech_playlists_improved_ux').isOn
  const user = useUser()
  const managementOverlay = useOverlay()
  const playlistsOverlay = useOverlay()
  const createPlaylistOverlay = useOverlay()
  const { overlay: loggedOutOverlay } = useLoggedOutOverlay()

  const onClick = (e) => {
    const loggedIn = user?.signedIn ?? false
    if (!loggedIn) {
      trackLink({ name: 'Playlists: logged out', action: 'click_action' })
      loggedOutOverlay?.handleClick?.(e)
    } else {
      trackLink({ name: 'Playlists: logged in', action: 'click_action' })
      const overlay = improvedUxEnabled ? managementOverlay : playlistsOverlay
      overlay.handleClick(e)
    }
  }

  const label = t('playlists.label', 'Playlists')

  // todo: figure out if it's possible to show just the icon in color
  const useColoredButton = false // improvedUxEnabled && isInSomePlaylist
  const isInSomePlaylist = Boolean(item?.playlists)

  return (
    <>
      <ChewableButton
        asIconButton={asIconButton}
        size={size}
        onClick={onClick}
        Icon={improvedUxEnabled && isInSomePlaylist ? ContentCheck : ContentAdd}
        data-testid="playlists-dialog"
        emphasis="low"
        color={useColoredButton ? 'blue' : null}
        {...props}
      >
        {label}
      </ChewableButton>
      {improvedUxEnabled && (managementOverlay.isOpen || managementOverlay.transitioning) && (
        <PlaylistManagementOverlay target={managementOverlay} sessionId={sessionId} />
      )}
      {!improvedUxEnabled && (
        <>
          {(playlistsOverlay.isOpen || playlistsOverlay.transitioning) && (
            <PlaylistsOverlay
              {...playlistsOverlay}
              createPlaylistOverlay={createPlaylistOverlay}
              sessionId={sessionId}
            />
          )}
          {(createPlaylistOverlay.isOpen || createPlaylistOverlay.transitioning) && (
            <CreatePlaylistOverlay {...createPlaylistOverlay} sessionId={sessionId} />
          )}
        </>
      )}
    </>
  )
}
