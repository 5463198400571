import React, { Children } from 'react'
import { i18n } from '@fs/zion-locale'
import { useTranslation } from 'react-i18next'
import { ListItem, ListSubSection } from '@fs/zion-ui'
import { getProspectiveLanguages } from '../../../lib/helpers'
import getCommunityLink from './helpers'
import { helpConfig } from '../../../config'
import AskUsAnythingButton from './AskUsAnythingButton'

const { researchHelp } = helpConfig
const helpLink = researchHelp[i18n.language] ? researchHelp[i18n.language] : researchHelp.en

const HelpItems = ({ dense = false, onClick, removeFiller = false }) => {
  const fsCommunityLink = getCommunityLink()
  const [t] = useTranslation()

  const rtItems = []
  if (!getProspectiveLanguages().includes(i18n.language)) {
    rtItems.push({
      'data-testid': 'self-help-faq',
      linkName: 'Help: FAQ',
      primaryText: t('help.overlay.rootstech.faq', 'Frequently Asked Questions'),
      onClick,
      to: '/faq',
      dense,
      startElement: removeFiller ? undefined : <ListItem.Filler />,
    })
  }
  rtItems.push({
    'data-testid': '1on1-help-email',
    linkName: 'Help: Contact Us',
    primaryText: t('virtual-booth.contact-us-email', 'Contact Us by Email'),
    onClick,
    to: 'mailto:info@rootstech.org',
    target: '_blank',
    dense,
    startElement: removeFiller ? undefined : <ListItem.Filler />,
  })

  const fsItems = [
    {
      'data-testid': 'self-help-helpCenter',
      linkName: 'Help: FamilySearch Help Center',
      primaryText: t('help.overlay.help-center.primary', 'Help Center'),
      onClick,
      to: 'https://community.familysearch.org/en/categories/familysearch-help',
      target: '_blank',
      dense,
      startElement: removeFiller ? undefined : <ListItem.Filler />,
    },
    {
      'data-testid': 'community-help-community',
      linkName: 'Help: FamilySearch Community',
      primaryText: t('help.overlay.community.primary', 'Community'),
      onClick,
      to: fsCommunityLink,
      target: '_blank',
      dense,
      startElement: removeFiller ? undefined : <ListItem.Filler />,
    },
    {
      'data-testid': '1on1-help-research',
      linkName: 'Help: Research Help',
      primaryText: t('help.overlay.research.primary', 'Research Help'),
      onClick,
      to: helpLink,
      target: '_blank',
      dense,
      startElement: removeFiller ? undefined : <ListItem.Filler />,
    },
  ]

  return (
    <>
      <ListSubSection heading={t('global.rootstech', 'Rootstech')} fullWidth>
        {Children.toArray(rtItems.map((item) => <ListItem {...item} />))}
        <AskUsAnythingButton
          asComponent={ListItem}
          primaryText={t('chat.button', 'Chat')}
          data-testid="menu-help-chat"
          onClick={onClick}
          dense={dense}
          startElement={removeFiller ? undefined : <ListItem.Filler />}
        />
      </ListSubSection>
      <ListSubSection heading={t('global.familysearch', 'FamilySearch')} fullWidth>
        {Children.toArray(fsItems.map((item) => <ListItem {...item} />))}
      </ListSubSection>
    </>
  )
}

export default HelpItems
