import { isConferencePromo, isSessionDetail, traverseAndModify } from '../../lib'

const isSessionObject = (obj) => {
  return (obj.contentId && isConferencePromo(obj)) || (obj.id && isSessionDetail(obj))
}

/**
 * Given the response from BSP, walks through the entire object like a tree and pulls out all of the session ids
 * @param {Object} data the response from BSP: OneOffPageDetail, SessionDetail, etc.
 * @returns {Array} a list of session ids from the object
 */
export const getSessionIds = (data) => {
  const ids = []
  traverseAndModify(data, isSessionObject, (obj) => {
    ids.push(obj.id || obj.contentId)
  })
  // dedup
  return [...new Set(ids)]
}

export default null
