import axios from '@fs/zion-axios'
import { i18n } from '@fs/zion-locale'
import SERVICE from './serviceName'

// DOCS: /service/rootstech/registration'

const BASE = `${SERVICE}/registration`
const lang = i18n.language
const config = {
  headers: { 'Accept-Language': lang, 'Content-Language': lang, 'Content-Type': 'application/json' },
  data: {},
}

export async function postRegistration(body) {
  // { "firstName":"Jack", "lastName":"Black", "email":"black.jack@gmail.com", "mobile":"123-456-7890", "country":"United States", "subscribeToMessages":true, "agreedToTermsAndPrivacy":true }
  return axios.post(`${BASE}/vendor/rootstech`, body, config)
}

export async function postSubscription(body) {
  // { "firstName":"Jack", "lastName":"Black", "email":"black.jack@gmail.com", "mobile":"123-456-7890", "subscribeToMessages":true, "agreedToTermsAndPrivacy":true }
  return axios.post(`${BASE}/subscription`, body, config)
}

export async function getUsersSubscriptions(body) {
  return axios.post(`${BASE}`, body, config)
}
