import { getPlaylistsByItems } from '../../../../api'
import { chunkArray, traverseAndModify } from '../../../../lib'

const CHUNK_LIMIT = 25

export const getChunkedPlaylistsByItems = async (sessionIds = []) => {
  const chunkedRequests = chunkArray(sessionIds, CHUNK_LIMIT)
  const requests = chunkedRequests.map((ids) => getPlaylistsByItems({ ids }))
  const responses = await Promise.all(requests)

  // The response is returned as a map of sessionIds to playlists
  const flattenedData = responses
    .map(({ data }) => data)
    // combine all responses into a single object
    .reduce((acc, item) => ({ ...acc, ...item }), {})

  // Filter out any ids that don't have any playlists
  const filteredData = Object.fromEntries(Object.entries(flattenedData).filter(([, { results }]) => results.length > 0))

  return filteredData
}

export const injectPlaylists = (data, playlistItems) => {
  const injectedData = traverseAndModify(
    data,
    // it could be a contentId for a promo, or just an id for a session
    ({ id, contentId }) => playlistItems[id] || playlistItems[contentId],
    (obj) => {
      const { id, contentId } = obj
      obj.playlists = playlistItems[id || contentId].results
    }
  )
  return injectedData
}
