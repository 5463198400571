import React from 'react'
import { ListItem, ListSubSection } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { useFeatureFlag } from '@fs/zion-flags'
import {
  useEventMenuItems,
  useGetInvolvedMenuItems,
  useHowToAttendMenuItems,
  usePastEventMenuItems,
} from '../../lib/helpers/menuHelper'

export const MenuSubsection = ({ items, heading }) => {
  return (
    <ListSubSection heading={heading} fullWidth>
      {items.map(({ disabledLocales, ...link }) => (
        <ListItem key={link.to} {...link} />
      ))}
    </ListSubSection>
  )
}

export const AfterConferencePhaseItems = ({ dense = false, onClick, removeFiller = false }) => {
  const [t] = useTranslation()
  const pastEventItems = usePastEventMenuItems(dense, onClick, removeFiller)
  const getInvolvedItems = useGetInvolvedMenuItems(dense, onClick, removeFiller)

  return (
    <>
      {pastEventItems?.length > 0 ? (
        <MenuSubsection items={pastEventItems} heading={t('navigation.past-event.label', 'RootsTech 2025')} />
      ) : null}
      {getInvolvedItems?.length > 0 ? (
        <MenuSubsection items={getInvolvedItems} heading={t('navigation.get-involved.label', 'Get Involved')} />
      ) : null}
    </>
  )
}

export const RegistrationPhaseItems = ({ dense = false, onClick, removeFiller = false }) => {
  const [t] = useTranslation()
  const howToAttendItems = useHowToAttendMenuItems(dense, onClick, removeFiller)
  const eventItems = useEventMenuItems(dense, onClick, removeFiller)
  const getInvolvedItems = useGetInvolvedMenuItems(dense, onClick, removeFiller)

  return (
    <>
      {howToAttendItems?.length > 0 ? (
        <MenuSubsection
          items={howToAttendItems}
          heading={t('navigation.how-to-attend.label', 'How To Attend in 2025')}
        />
      ) : null}
      {eventItems?.length > 0 ? (
        <MenuSubsection items={eventItems} heading={t('navigation.event.label', 'RootsTech 2025')} />
      ) : null}
      {getInvolvedItems?.length > 0 ? (
        <MenuSubsection items={getInvolvedItems} heading={t('navigation.get-involved.label', 'Get Involved')} />
      ) : null}
    </>
  )
}

export const CountdownPhaseItems = ({ dense = false, onClick, removeFiller = false }) => {
  const [t] = useTranslation()
  const howToAttendItems = useHowToAttendMenuItems(dense, onClick, removeFiller)
  const eventItems = useEventMenuItems(dense, onClick, removeFiller)
  const getInvolvedItems = useGetInvolvedMenuItems(dense, onClick, removeFiller)

  return (
    <>
      {howToAttendItems?.length > 0 ? (
        <MenuSubsection
          items={howToAttendItems}
          heading={t('navigation.how-to-attend.label', 'How To Attend in 2025')}
        />
      ) : null}
      {eventItems?.length > 0 ? (
        <MenuSubsection items={eventItems} heading={t('navigation.event.label', 'RootsTech 2025')} />
      ) : null}
      {getInvolvedItems?.length > 0 ? (
        <MenuSubsection items={getInvolvedItems} heading={t('navigation.get-involved.label', 'Get Involved')} />
      ) : null}
    </>
  )
}

// TODO: duplicate of countdown phase items; did we want these to be different?
export const ConferencePhaseItems = ({ dense = false, onClick, removeFiller = false }) => {
  const [t] = useTranslation()
  const howToAttendItems = useHowToAttendMenuItems(dense, onClick, removeFiller)
  const eventItems = useEventMenuItems(dense, onClick, removeFiller)

  return (
    <>
      {howToAttendItems?.length > 0 ? (
        <MenuSubsection
          items={howToAttendItems}
          heading={t('navigation.how-to-attend.label', 'How To Attend in 2025')}
        />
      ) : null}
      {eventItems?.length > 0 ? (
        <MenuSubsection items={eventItems} heading={t('navigation.event.label', 'RootsTech 2025')} />
      ) : null}
    </>
  )
}

export const RootsTechEventItems = ({ dense = false, onClick, removeFiller = false }) => {
  const siteExperienceFlag = useFeatureFlag('rootstech_site_experience')
  const rootstechTreatments = [
    { key: 'registration', type: RegistrationPhaseItems },
    { key: 'countdown', type: CountdownPhaseItems },
    { key: 'conference', type: ConferencePhaseItems },
    { key: 'afterConference', type: AfterConferencePhaseItems },
    { key: 'announce', type: AfterConferencePhaseItems },
  ]

  const findEventPhase = (treatment, siteExperience) => {
    return treatment.key === siteExperience
  }

  const Tag = rootstechTreatments.find((treatment) => findEventPhase(treatment, siteExperienceFlag.treatment)).type

  return <Tag dense={dense} onClick={onClick} phase={siteExperienceFlag.treatment} removeFiller={removeFiller} />
}
