import React, { Children } from 'react'
import { Separator, useContainerWidth } from '@fs/zion-ui'
import PropTypes from 'prop-types'
import { Card, CardGrid, SectionCard, getCustomImage, getIsLive, getPercentageWatched } from '../../../lib'
import { PillButton } from '../../../lib/buttons'
import { mexicanDancersBannerJpg } from '../../../images'
import { getIsPlaylistable } from '../../../hooks'
import { validFlavors } from '../../../config'

function RainbowCarouselSection({
  overline,
  title,
  items,
  description,
  ctaUrl,
  ctaLabel,
  flavor = 'apple',
  image = mexicanDancersBannerJpg,
  position = 0,
  largeMobileImg,
  smallMobileImg,
}) {
  const atWidth = useContainerWidth()

  return title ? (
    <SectionCard
      overline={overline}
      heading={title}
      description={description}
      flavor={flavor}
      image={image}
      actions={
        ctaUrl && (
          <PillButton to={ctaUrl} data-testid="cta-button" linkName={`Section: ${title}: ${ctaLabel}`}>
            {ctaLabel}
          </PillButton>
        )
      }
      data-testid={`cms-section-${position}`}
    >
      <Separator size="xxs" />
      <CardGrid limit={atWidth({ default: 4, lg: 3, xl: 4 })} seeMoreMode="byRow">
        {Children.toArray(
          items
            ?.filter((contentItem) => contentItem.contentId || contentItem.image?.id || contentItem.url)
            ?.map((contentItem, itemIndex) => {
              const contentId = contentItem?.contentId ?? contentItem?.contentUUID
              const to = contentItem?.url || contentItem?.sessionUrl
              const percentageWatched = getPercentageWatched(contentItem)
              return (
                <Card
                  image={getCustomImage(contentItem)}
                  noCrop={to?.includes('/expohall/')}
                  heading={contentItem?.title ?? ''}
                  description={contentItem?.description ?? ''}
                  conferenceYear={contentItem?.conferenceYear || undefined}
                  contentId={contentId}
                  data-testid={`featured-sessions-row-card${itemIndex}`}
                  startTimestamp={contentItem?.date}
                  isInPlaylist={contentItem.isInPlaylist}
                  isPlaylistable={getIsPlaylistable(contentItem)}
                  isLive={getIsLive(contentItem)}
                  key={contentId}
                  contentLocale={contentItem.contentLocale}
                  duration={contentItem?.videoDuration}
                  largeMobileImg={largeMobileImg}
                  smallMobileImg={smallMobileImg}
                  to={to}
                  percentageWatched={percentageWatched}
                  item={contentItem}
                />
              )
            })
        )}
      </CardGrid>
    </SectionCard>
  ) : null
}

export default RainbowCarouselSection

RainbowCarouselSection.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  description: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaLabel: PropTypes.string,
  flavor: PropTypes.oneOf(validFlavors),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      UUID: PropTypes.string,
      creators: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          profilePhoto: PropTypes.shape({
            custom: PropTypes.shape({
              image: PropTypes.shape({
                src: PropTypes.string,
              }),
            }),
          }),
        })
      ),
      description: PropTypes.string,
      image: PropTypes.shape({
        custom: PropTypes.shape({
          image: PropTypes.shape({
            src: PropTypes.string,
          }),
        }),
      }),
      objectTypeId: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
      __typename: PropTypes.string,
    })
  ),
}
